@use '~@angular/material' as mat;
.seq-theme {
  .mat-card {
    margin-bottom: 24px;
    padding: 30px;

    .mat-card-header {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex-direction: row;
      // align-items: center;
      white-space: wrap;
      font-size: 1.4em;
      font-weight: 100;
      color: rgba(0, 0, 0, 0.87);
      padding: 0 0 24px 0;
      margin: 0;

      button {
        margin-left: 10px;
        flex-grow: 0;
        font-weight: bold;
      }

      .mat-card-header-text {
        display: none;
      }
    }

    .mat-card-footer {
      display: flex;
      margin: 0;

      button {
        margin-left: 10px;
        flex-grow: 0;
      }
    }

    .mat-card-content {
      font-size: 16px;

      span.details_label {
        display: block;
        font-size: 16px;
        color: #20262D;
        font-weight: 600;
      }

      p {
        // display: flex;
        // flex-direction: column;
        margin: 0 0 25px;
      }
    }

    .card-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .card-column {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(50% - 12px);
      .mat-form-field{
        width: 100%;
      }
    }

    .card-column-33 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(33.33% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(100% - 0px);
        margin-bottom: 15px;
      }
    }

    .card-column-20 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(20% - 12px);
    }

    .card-column-25 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(25% - 12px);
    }

    .card-column-60 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(60% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(70% - 12px);  
      }
    }

    .card-column-30 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(30% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(30% - 12px);  
      }
    }

    .card-column-40 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(40% - 12px);
      @media screen and (max-width: 1365px) {
        width: calc(40% - 12px);  
      }
    }

    .card-column-45 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(45% - 12px);
      @media screen and (max-width: 598px) {
        width: calc(100% - 12px);  
      }
    }
    
    .card-column-70 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(70% - 12px);
    }

    .card-column-75 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(75% - 12px);
    }

    .card-column-80 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(80% - 12px);
    }

    .card-column-100 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(111% - 12px);
    }

    .card-column-100 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(100% - 12px);
    }

    .card-column-third {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(33% - 12px);
    }

    .card-column-two-third{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: calc(67% - 12px);
    }

    .group-title {
      color: #061a30;
      margin-bottom: 12px;
    }

    .group-title.group-subtitle {
      color: #061a30;
      margin-bottom: 24px;
    }

    .group-subtitle {
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 24px;
    }

    .mobile-label {
      display: none;
    }

    .mat-row {
      min-height: 56px;
    }
  }

  .search_by {
    border: 1px solid rgba(0,0,0,.24);
    padding: .25em .75em;
    margin: 0 12px 0 0;
    border-radius: 4px;
    font-size: .65em;
    line-height: 1.125!important;

    .mat-form-field-infix {
      width: 300px;
      border-top: 0;
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper,
    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
      height: 0;
      background-color: transparent !important;
    }

    .mat-form-field-suffix .mat-icon-button {
      width: 24px;
      height: 24px;

      &:hover {
        background: transparent;
      }

      .mat-icon {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.64);
      }
    }
  }

  .search_by.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .search_by .mat-input-element::placeholder {
    display: none;
    opacity: 0;
  }

  .search_by .mat-input-element::placeholder,
  .search_by .mat-input-element::-webkit-input-placeholder,
  .search_by .mat-input-element::-moz-placeholder {
    opacity: 0;
  }

  .filter_by {
    max-width: 300px;
    font-size: 16px;
    padding: 0.35em 0.75em;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;

    .mat-select-placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  ::selection {
    background: mat.get-color-from-palette($seq-accent);
    color: #fff;
    text-shadow: none;
  }
  ::-webkit-selection {
    background: mat.get-color-from-palette($seq-accent);
    color: #fff;
    text-shadow: none;
  }
  ::-moz-selection {
    background: mat.get-color-from-palette($seq-accent);
    color: #fff;
    text-shadow: none;
  }

  .color-primary {
    color: mat.get-color-from-palette($seq-primary);
  }
  .mat-stroked-button:not([class*="mat-elevation-z"]),
  .seq-theme .mat-flat-button:not([class*="mat-elevation-z"]) {
    //border-color: rgba(0, 0, 0, 0.24);
    border-color: #20262D;
  }
  .color-accent {
    color: mat.get-color-from-palette($seq-accent);
  }

  .chip {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 4px;
    align-items: center;
    margin: 4px;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    color: rgba(0, 0, 0, 0.87);
    .mat-checkbox-inner-container {
      height: 16px;
      width: 16px;
    }
    .mat-checkbox-layout .mat-checkbox-label {
      line-height: 18px;
    }
  }

  .chip-parent {
    position: relative;

    .chip-label {
      padding-left: 44px;
    }

    .chip-child {
      position: absolute;
      margin: 0;
      padding: 7px 12px 7px 10px;
      border: 0;
      border-radius: 4px 0 0 4px;
      top: 0;
      left: 0;
    }
  }

  .primary {
    background-color: mat.get-color-from-palette($seq-primary);
    color: white;
    border: 1px solid mat.get-color-from-palette($seq-primary);
  }
  .small {
    color: rgba(0, 0, 0, 0.64);
  }

  .settings .mat-tab-header {
    .mat-icon {
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  hr {
    margin-bottom: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .label-hr {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 16px 0;

    span {
      font-size: 1.3em;
      font-weight: 100;
      padding-right: 8px;
      color: mat.get-color-from-palette($seq-primary);
    }

    &:after {
      content: "";
      flex: 1;
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    }
    &:before {
      content: "";
      width: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    }

    &:after {
      margin-left: 0.25em;
    }
  }

  .mobile,
  .on-mobile {
    display: none !important;
  }

  .width-0 {
    width: 0%;
    display: none;
    padding: 0 !important;
    margin: 0 !important;
  }

  .width-5 {
    width: calc(5% - 12px);
  }
  .width-10 {
    width: calc(10% - 12px);
  }
  .width-12{
    width: calc(12% - 12px);
  }
  .width-13{
    width: calc(13% - 12px);
  }
  .width-14{
    width: calc(14% - 12px);
  }
  .width-15 {
    width: calc(15% - 12px);
  }
  .width-17{
    width: calc(17% - 12px);
  }
  .width-19{
    width: calc(19% - 12px);
  }
  .width-20 {
    width: calc(20% - 12px);
  }
  .width-25 {
    width: calc(25% - 12px);
  }
  .width-30 {
    width: calc(30% - 12px);
  }
  .width-35 {
    width: calc(35% - 12px);
  }
  .width-40 {
    width: calc(40% - 12px);
  }
  .width-45 {
    width: calc(45% - 12px);
  }
  .width-50 {
    width: calc(50% - 12px);
  }
  .width-55 {
    width: calc(55% - 12px);
  }
  .width-60 {
    width: calc(60% - 12px);
  }
  .width-80 {
    width: calc(80% - 12px);
  }
  .actions-column {
    min-width: 100px;
  }

  iframe {
    border-width: 0px;
    border-style: none;
    border-color: none;
    border-image: none;
  }

  .mat-radio-button {
    margin: 0 12px;
  }
    
}
.subscriber-container{
  text-align: center;
  width: 70%;
  float: left;
  #subscriber{
    width: 100%;
    video{
      width: 100%;
    }
  }
}
.publisher-container{
    float: right;
    width: 250px;
    position: relative;
    height: auto;
    min-height: 145px;
    text-align: left;
    margin-left: 30px;
    background-color: #ccc;
    #publisher{
      width: 100%;
      max-height: 189px;
      video{
        width: 100%;
      }
    }
}

.facilitator-container{
  display: flex;
  @media screen and (max-width: 1112px) {
    position: absolute;
    top: 0;  
  }
  
  #subscriber {
    position: relative;
    z-index: 1;
    top: 0; bottom: 0; left: 0; right: 0;
    display: flex;
    width: 100%;
    video {
      width: 100%;
    }
  }
}

#publisher {
  position: relative;
  z-index: 2;

  video {
    position: absolute;
    bottom: 0;
    width: 33%;
  }
}
.nomargin{
  margin: 0!important;
}
#patient_table{
  .mat-sort-header-container{
    display: -webkit-box!important;
    .mat-sort-header-arrow{
      margin-top: 3px;
    }
  }
}
.cdk-overlay-pane{
  height: auto!important;
}
.ethnicity-map-fix{
  .cdk-overlay-pane{
    position: absolute!important;
    top: 20px;
  }
}
.mb20{
  margin-bottom: 20px;
}
.mb40{
  margin-bottom: 40px;
}
.mat-select-value{
  font-size: 16px!important;
}
.mat-select-panel{
  .mat-option{
    font-size: 16px!important;
  }
}
.template-container{
  thead{
    display: none;
  }
  .mat-paginator-page-size, .mat-paginator-range-label, .mat-paginator-navigation-first, .mat-paginator-navigation-last{
    display: none;
  }
  .mat-icon-button{
    width: 55px;
    height: 75px;
    position: absolute;
    top: 103px;
    .mat-paginator-icon{
      width: 80px;
    }
  }
  .mat-paginator-navigation-previous{
    left: -25px;
  }
  .mat-paginator-navigation-next{
    right: 0;
  }
}
.label-style{
  display: block;
  font-weight: bold;
}
.text-center{
  text-align: center;
}
.nomargintop{
  margin-top: 0!important;
}
// #chordsChart .cytobands .block .tile{
//   stroke: #007AAF;
// }
/*for dashboard chart GWIS-343*/
#dashboard_chart{
  #pie_chart, #report_chart{
    svg{
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translate(-50%);
    }
    g[ng-reflect-label="No Data"], g[ng-reflect-label="Awaiting Data"], g[ng-reflect-label="Genome Data"]{
      display: none;
    }
    .chart-legend{
      position: absolute;
      bottom: 20px;
      left: 30%;
      transform: translate(-30%);
      width: 100%!important;
      div{
        width: 100%!important;
        .legend-title{
          display: none;
        }
        .legend-labels{
          background: transparent;
          text-align: center;
          .legend-label{
            display: inline-block;
            .legend-label-color{
              border-radius: 8px;
            }
            .legend-label-text{
              font-size: 16px;
              color: #20262D;
              overflow: visible;
              &:hover{
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
  #report_chart{
    svg{
      bottom: 60px;
      top: auto;
      g.bar-chart {
        .x.axis{
          display: none;
        }
      }
    }
  }
}
.ngx-charts-tooltip-content{
  padding: 15px 10px!important;
}
#viewerContainer{
  left: 0!important;
}
#sidebarContainer{
  display: none!important;
}
/*changed position of chromosome label*/
#chordsChart{
  svg{
    height: 650px;
    g.all{
      transform: translate(47%, 48%);
    }
    .cs-layout{
      font-weight: bold;
      g{
        &:last-child{
          display: none;
        }
      }
    }
  }
}
#chr1 ~ text{
  transform: translate(60px, 20px) rotate(-371deg)
}
#chr2 ~ text{
  transform: translate(167px, 80px) rotate(-394deg);
}
#chr3 ~ text{
  transform: translate(273px, 178px) rotate(-423deg);
}
#chr4 ~ text{
  transform: translate(324px, 292px) rotate(-448deg)
}
#chr5 ~ text{
  transform: translate(324px, 399px) rotate(-470deg);
}
#chr6 ~ text{
  transform: translate(279px, 492px) rotate(231deg);
}
#chr7 ~ text{
  transform: translate(186px, 566px) rotate(217deg);
}
#chr8 ~ text{
  transform: translate(106px, 610px) rotate(199deg);
}
#chr9 ~ text{
  transform: translate(17px, 628px) rotate(183deg);
}
#chr10 ~ text {
  transform: translate(-70px, 622px) rotate(168deg);
}
#chr11 ~ text {
  transform: translate(-149px, 592px) rotate(152deg);
}
#chr12 ~ text {
  transform: translate(-212px, 540px) rotate(135deg);
}
#chr13 ~ text {
  transform: translate(-256px, 482px) rotate(120deg);
}
#chr14 ~ text {
  transform: translate(-286px, 417px) rotate(107deg);
}
#chr15 ~ text {
  transform: translate(-311px, 352px) rotate(96deg);
}
#chr16 ~ text {
  transform: translate(-320px, 288px) rotate(86deg);
}
#chr17 ~ text {
  transform: translate(-280px, 231px) rotate(70deg);
}
#chr18 ~ text {
  transform: translate(-271px, 179px) rotate(62deg);
}
#chr19 ~ text {
  transform: translate(-247px, 137px) rotate(53deg);
}
#chr20 ~ text {
  transform: translate(-212px, 102px) rotate(44deg);
}
#chr21 ~ text {
  transform: translate(-176px, 72px) rotate(36deg);
}
#chr22 ~ text {
  transform: translate(-146px, 50px) rotate(29deg);
}
#chrX ~ text {
  transform: translate(-89px, 27px) rotate(17deg);
}
#chrY ~ text {
  transform: translate(-20px, 15px) rotate(4deg);
}
.variant{
  .circos-tooltip{
    left: 49.6%!important;
    top: 471px!important;
    color: #000!important;
    background: #fff!important;
    border: 1px solid transparent!important;
    opacity: 1!important;
    min-width: 208px!important;
    min-height: 55px;
  }
}
.mat-form-field-hint-wrapper{
  font-size: 14px;
  margin-top: 8px;
}
#unitField{
  position: absolute;
  width: 96px;
  right: 0;
  top: 9px;
  .mat-select-trigger{
    border-top-width: 1px!important;
  }
}
#unitField1{
  position: absolute;
  width: 96px;
  right: 0;
  top: 9px;
  .mat-select-trigger{
    border-top-width: 0px!important;
  }
}
.mat-radio-label {
  width: fit-content !important;
}
#edit-template-1{
  .mat-vertical-content-container{
    .mat-vertical-stepper-content{
      visibility: hidden;
      .mat-vertical-content{
        padding: 10px 18px 24px;
      }
    }
  }
}
.relative{
  position: relative!important;
}
.ml8{
  margin-left: 8px!important;
}
#edit-template{
  padding-left: -20px;
  quill-editor{
    width: 100%;
  }
  .ql-toolbar{
    border: 2px solid #20262D !important;
  }
  .ql-container{
    border: 2px solid #20262D !important;
    border-top: none!important;
  }
  .ql-snow{
    max-height: 210px;
    .ql-editor{
      font-size: 16px;
      height: 208px;
    }
  }
  .ql-color-picker, .ql-blockquote, .ql-indent, .ql-align, .ql-direction, .ql-code-block{
    pointer-events: none;
    opacity: 0.6;
  }
}
/*for ethnicity-data in edit template*/
.ethnicity-container{
  #svgLine{
    margin-top: -54px;
  }
}